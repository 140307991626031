<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CModalExtended :closeOnBackdrop="false" :show.sync="ModalActive" @update:show="$emit('Close')">
      <template slot="header">
        <div class="header-alert">
          <div
            class="swal2-icon swal2-warning swal2-icon-show"
            style="display: flex"
          >
            <div class="swal2-icon-content">!</div>
          </div>
          <div class="flex-grow-1 msg">
            {{ `${$t("label.the")} ${$t("label.file")}`  }}
            <span class="msg-name">{{ File?.FileName ?? '' }}</span>
            {{ `${$t("label.successUpload")}` }}
          </div>
        </div>
      </template>
      <div style="color: #000">
        <div class="main-alert">
          <div class="correct">
            {{ getCorrectCount }} {{ $t("label.rightRows") }}
          </div>
          <div class="errors" v-if="File?.Error && getErrorsCount != 0">
            {{ getErrorsCount }} {{ $t("label.wrongRows") }}
          </div>
          <div class="footer-errors" v-if="File?.Error && getErrorsCount != 0">
            {{ `(${$t("label.errorsStaff")})` }}
          </div>
        </div>
      </div>
      <div slot="footer">
        <CButton
          shape="square"
          color="success"
          class="mr-2"
          @click="onBtnExport()"
          v-if="File?.Error && getErrorsCount != 0"
        >
          <CIcon name="cil-file-excel" />&nbsp; XLSX
        </CButton>
        <CButton shape="square" color="wipe" @click="$emit('Close')">
          <CIcon name="x" />&nbsp; {{ $t("button.exit") }}
        </CButton>
      </div>
    </CModalExtended>
  </div>
</template>
<script>
import YardManagement from '@/_mixins/yardManagement';
import { mapState } from 'vuex';
//DATA
function data() {
  return {
    Loading: false,
    ModalActive: false,
  };
}

// METHODS
async function onBtnExport() {
  this.Loading = true;
  if (this.File.StowagePlanningBlJson && this.File.StowagePlanningBlJson.length != 0) {
    let title = this.File.FileName ?? '';
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let dataList = [];
    let excelHeader = this.Field();
    if (this.File?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BOOKING_CONTAINER_LIST) {
      dataList = this.File?.StowagePlanningBlJson?.filter(item => item.CargoJson&&item.CargoJson[0].FgError)?.map((item, index) => {
        let CargoJson = item?.CargoJson&&item?.CargoJson[0] ? item?.CargoJson[0] : {};
        return {
          Nro: index+1,
          ContainerCode: CargoJson.ContainerCode ?? '',
          BlNro: CargoJson.BlNro ?? '',
          TpCargoCode: CargoJson.TpCargoCode ?? '',
          Consignee: CargoJson.Consignee ?? '',
          IsoCode: CargoJson.IsoCode ?? '',
          Status: CargoJson.TpCargoStatusName ?? '',
          Seals: `S1:${CargoJson.Seal1??'N/A'}, S2:${CargoJson.Seal2??'N/A'}, S3:${CargoJson.Seal3??'N/A'}, S4:${CargoJson.Seal4??'N/A'}`,
          ShippingLineCode: CargoJson.ShippingLineCode ?? '',
          PortActivityName: CargoJson.PortActivityName ?? '',
          Condition: CargoJson.Condition ?? '',
          ImdgCode: CargoJson.ImdgCode ?? '',
          LoadPort: CargoJson.LoadPort ?? '',
          DischargePort: CargoJson.DischargePort ?? '',
          Weigth: CargoJson.Weigth ?? 0,
          DescriptionOfGoods: CargoJson.DescriptionOfGoods ?? '',
          Errors: item.JsonError.map((Error) => {
            return `${this.$t("label.column")} ${Error.ColumnName}: ${Error['ErrorMessage'+_lang]??''}`;
          }).join(","),
        }
      });
    }
    await this.excel([excelHeader], [dataList], title, '', title)
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }else{
    this.$notify({
      group: "container",
      title: "¡Error!",
      text:  this.$t('label.noDataAvailable').toUpperCase(),
      type: "error",
    });
  }
  this.Loading = false;
}

function Field() {
  if (this.File?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BOOKING_CONTAINER_LIST) {
    return [
      { text: '#', value: "Nro", aux: "", json: "", isNotFormater: true },
      { text: this.$t('label.containerCode'), value: 'ContainerCode', aux: "", json: "" },
      { text: this.$t('label.type'), value: 'TpCargoCode', aux: "", json: "" },
      { text: this.$t('label.isoCode'), value: 'IsoCode', aux: "", json: "" },
      { text: this.$t('label.booking'), value: 'BlNro', aux: "", json: "" },
      { text: this.$t('label.exporter'), value: 'Consignee', aux: "", json: "" },
      { text: this.$t('label.status'), value: 'Status', aux: "", json: "" },
      { text: `${this.$t('label.Seal')}S`, value: 'Seals', aux: "", json: "" },
      { text: this.$t('label.newShippingLine'), value: 'ShippingLineCode', aux: "", json: "" },
      { text: this.$t('label.portActivity'), value: 'PortActivityName', aux: "", json: "" },
      { text: this.$t('label.condition'), value: 'Condition', aux: "", json: "" },
      { text: 'IMDG', value: 'ImdgCode', aux: "", json: "" },
      { text: 'POL', value: 'LoadPort', aux: "", json: "" },
      { text: 'POD', value: 'DischargePort', aux: "", json: "" },
      { text: this.$t('label.weight'), value: 'Weight', aux: "", json: "" },
      { text: this.$t('label.DescriptionOfGoods'), value: 'DescriptionOfGoods', aux: "", json: "" },
      { text: this.$t('label.errors'), value: 'Errors', aux: "", json: "" }
    ]
  }else{
    return [];
  }
}

//COMPUTED
function getErrorsCount() {
  return this.File.Error;
}
function getCorrectCount() {
  return this.File.Correct;
}

export default {
  name: "alert-modal",
  mixins: [YardManagement],
  data,
  props: {
    modal: Boolean,
    File: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onBtnExport,
    Field,
  },
  computed: {
    getErrorsCount,
    getCorrectCount,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      myDataItinirary: state => state.planificacionestiba.myDataItinirary,
    })
  },
  watch: {
    modal: function (NewVal) {
      this.ModalActive = NewVal;
    },
  },
};
</script>
<style scoped>
.main-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.msg {
  font-weight: bold;
  text-align: center;
}
.msg-name {
  font-weight: normal;
}
.correct {
  color: #1aff1a;
  font-weight: bold;
  margin-bottom: 10px;
}
.errors {
  color: #ff0101;
  font-weight: bold;
  text-align: center;
}
.footer-errors {
  margin-top: 10px;
  font-weight: bold;
}

.header-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>